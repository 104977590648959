import React, { useEffect } from "react";
import styles from "./_css/virtualAssistantPopUp.module.css";
import clsx from "clsx";
import sharedConfig from "_configs/sharedConfig";
import { userStore } from "users/_stores/userStore";
import logo from "../../../shared/assets/images/virtual-assistant/logo.svg";
import arrowRight from "../../../shared/assets/images/virtual-assistant/arrowRight.svg";
import closeIcon from "../../../shared/assets/images/cross.svg";
import { ButtonV2 } from "_common/ui/mui/buttons/ButtonV2";
import { HOURS_BEFORE_DISPLAY_AGAIN, VirtualAssistantUtils } from "_common/_utils/virtualAssistantUtils";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";

export function VirtualAssistantPopUp({ toLeft }: { toLeft?: boolean }) {
    const history = useHistory();
    const { t } = useTranslation();

    const [lastVisit, setLastVisit] = React.useState(VirtualAssistantUtils.getLastVisit());
    const [display, setDisplay] = React.useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(
            () => {
                setDisplay(true);
            },
            sharedConfig.isProd ? 30000 : 1000,
        );
        return () => clearTimeout(timeoutId);
    }, []);

    if (lastVisit && dayjs().diff(lastVisit, "hours") < HOURS_BEFORE_DISPLAY_AGAIN) return null;
    if (!sharedConfig.assistantVirtual.enable && !userStore.isAdmin) return null;
    if (!__BROWSER__) return null;

    return (
        <div
            className={clsx("position_fixed", styles.container, {
                [styles.hidden]: !display,
                [styles.toLeft]: toLeft,
                [styles.toRight]: !toLeft,
            })}
        >
            <div className={clsx("position_relative", styles.content)}>
                <div>
                    <img src={logo} alt={"virtual assistant logo"} />
                </div>
                <div>{t("virtualAssistant.popup.title")}</div>
                <div className={clsx("flex", styles.ctaContainer)}>
                    <ButtonV2
                        size={"small"}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            VirtualAssistantUtils.setLastVisit();
                            setLastVisit(dayjs());
                            history.push(URLS.virtualAssistant());
                        }}
                    >
                        <div className={"flex gap_10"}>
                            <span>{t("virtualAssistant.popup.cta")}</span>
                            <img src={arrowRight} alt={"go to arrow"} />
                        </div>
                    </ButtonV2>
                </div>
            </div>
            <div
                className={clsx("position_absolute", styles.close)}
                onClick={() => {
                    VirtualAssistantUtils.setLastVisit();
                    setLastVisit(dayjs());
                }}
            >
                <img src={closeIcon} />
            </div>
        </div>
    );
}
