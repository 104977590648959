import React from "react";
import { PropertiesPage } from "properties/listing/PropertiesPage";
import { PropertiesPageContext } from "properties/listing/PropertiesPageContext";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { PageRendererContext } from "properties/PropertiesPageContext";

export function BuyPage() {
    return (
        <PropertiesPageContext store={propertiesBuyingStore}>
            <PageRendererContext>
                <PropertiesPage />
            </PageRendererContext>
        </PropertiesPageContext>
    );
}
