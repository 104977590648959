import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_PURPOSE, PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";

export enum FILTER_TYPE {
    propertyType = "propertyType",
    default = "default",
}

export enum FILTER_KEYS {
    SQUARE_SURFACE_MAX = "squareSurface.max",
    SQUARE_SURFACE_MIN = "squareSurface.min",
    BATHROOMS_MAX = "bathrooms.max",
    BATHROOMS_MIN = "bathrooms.min",
    BEDROOMS_MAX = "bedrooms.max",
    BEDROOMS_MIN = "bedrooms.min",
    PRICE_MAX = "price.max",
    PRICE_MIN = "price.min",
    TYPE = "type",
    SUB_TYPE = "subType",
    LOCATION = "location",
    PURPOSE = "purpose",
    AMENITIES = "amenities",
    FEATURES = "features",
    STATUS = "status",
    UNIT_TYPE = "typeUnit",
    PRICE_DISPLAY = "priceIsNotDisplayed",
}

export const UNIT_SPECIFIC_FILTERS = [
    FILTER_KEYS.BATHROOMS_MAX,
    FILTER_KEYS.BATHROOMS_MIN,
    FILTER_KEYS.BEDROOMS_MAX,
    FILTER_KEYS.BEDROOMS_MIN,
    FILTER_KEYS.PRICE_MAX,
    FILTER_KEYS.PRICE_MIN,
    FILTER_KEYS.SQUARE_SURFACE_MAX,
    FILTER_KEYS.SQUARE_SURFACE_MIN,
];

export function isPropertyTypeOrSubType(supposedPropertyType: string, lang: TLang) {
    const allTypes = Object.values(PROPERTY_TYPE);
    const allPropertiesInAllLang: string[] = [];
    allTypes.map((type) =>
        allPropertiesInAllLang.push(reformatStringForUrls(getI18nExpByLang(lang, `property.typesUrl.${type}`))),
    );
    return allPropertiesInAllLang.includes(supposedPropertyType);
}

export function getFiltersValueKey(filters: TFilter[], filterKeys: FILTER_KEYS[]): TFilter[] {
    return filters
        .filter((filter) => filterKeys.some((filterKey) => filter.id.includes(filterKey)))
        .sort((a, b) => b.id.includes("max") - a.id.includes("max"));
}

export function getLabelFilterValue(filter: TFilter, purpose: PROPERTY_PURPOSE) {
    const { id: filterKey, value } = filter;
    if (filterKey === "type" && value?.[0]) {
        let key = `property.types.${value?.[0]}`;
        if (value?.[0] === PROPERTY_TYPE.condo && purpose === PROPERTY_PURPOSE.BUY) {
            key = "property.types.condoWithoutApartment";
        }
        return getI18nExpByLang(i18next.language, key);
    }
    if (filterKey.startsWith("bedroom")) {
        if (parseInt(value) === 0) return "Studio...";
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bedrooms")}...`;
    }
    if (filterKey.startsWith("bathroom")) {
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bathrooms")}...`;
    }
    if (filterKey.startsWith("price")) {
        if (parseInt(value) > 10000000) {
            return `${parseInt(value) / 100000}K...`;
        }
        return `${parseInt(value) / 100}...`;
    }
    if (filterKey.startsWith("surface")) {
        return `${value}...`;
    }
    if (filterKey.startsWith("status")) {
        return `${getI18nExpByLang(i18next.language, `propertyPage.${value}`)}...`;
    }
    if (filterKey.startsWith("amenities") || filterKey.startsWith("features")) {
        return `${getI18nExpByLang(i18next.language, `property.features.${filterKey.split(".")[1]}`)}...`;
    }
    return value;
}

export const updateSearchFilter = (listStore: ListStore<TPropertyListingMdl>, filters: TFilter[], reload = true) => {
    return new Promise((resolve) => {
        const locationIndex = filters.findIndex((filter) => filter.id === "location");
        const newFiltersIds = filters.map((filter) => filter.id);

        listStore.updateFilters([
            ...listStore.filters
                .filter((filter) => {
                    if (locationIndex > -1) {
                        return filter.id !== "location";
                    }
                    return true;
                })
                .filter((filter) => !newFiltersIds.includes(filter.id)),
            ...filters.filter(
                (filter) =>
                    ((filter.id.startsWith("amenities") || filter.id.startsWith("features")) && filter.value) ||
                    !(filter.id.startsWith("amenities") || filter.id.startsWith("features")),
            ),
        ]);
        if (reload) listStore.reload();
        resolve(listStore);
    });
};

export function getFeaturesOrAmenitiesFilters(filters: TFilter[]) {
    return filters.filter((filter) => filter.id.startsWith("amenities") || filter.id.startsWith("features"));
}

export function getUrlSearchFromFilter(
    filterType = FILTER_TYPE.default,
    search: string,
    filter?: TFilter,
    deleteFilter = false,
) {
    const searchParams = new URLSearchParams(search);
    switch (filterType) {
        case FILTER_TYPE.propertyType:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                searchParams.set(filter.id, filter.value.join(","));
            }
            return searchParams.toString();
        case FILTER_TYPE.default:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
            }
            if (deleteFilter) return searchParams.toString();
            if (filter) searchParams.set(filter.id, filter.value.toString());
            return searchParams.toString();
        default:
            return searchParams.toString();
    }
}

export function getUrlSearchFromFilters(filters: TFilter[]) {
    let search = "";
    filters.forEach((filter) => {
        switch (filter.id) {
            case FILTER_KEYS.PURPOSE:
            case FILTER_KEYS.LOCATION:
                break;
            case FILTER_KEYS.TYPE:
                search = getUrlSearchFromFilter(FILTER_TYPE.propertyType, search, filter);
                break;
            default:
                search = getUrlSearchFromFilter(FILTER_TYPE.default, search, filter);
        }
    });
    return search;
}

export function getFiltersFromQuery(query: { [key: string]: any }) {
    const filterFromQuery: TFilter[] = [];
    const filterTypesFromQuery: string[] = [];
    const filterSubTypesFromQuery: string[] = [];
    Object.entries(query).forEach(([filterKey, filterValue]) => {
        if (filterKey.startsWith(FILTER_KEYS.SQUARE_SURFACE_MAX)) {
            filterFromQuery.push({
                id: FILTER_KEYS.SQUARE_SURFACE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.SQUARE_SURFACE_MIN)) {
            filterFromQuery.push({
                id: FILTER_KEYS.SQUARE_SURFACE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.BATHROOMS_MAX)) {
            filterFromQuery.push({
                id: FILTER_KEYS.BATHROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.BATHROOMS_MIN)) {
            filterFromQuery.push({
                id: FILTER_KEYS.BATHROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.BEDROOMS_MAX)) {
            filterFromQuery.push({
                id: FILTER_KEYS.BEDROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.BEDROOMS_MIN)) {
            filterFromQuery.push({
                id: FILTER_KEYS.BEDROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.PRICE_MAX)) {
            filterFromQuery.push({
                id: FILTER_KEYS.PRICE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.PRICE_MIN)) {
            filterFromQuery.push({
                id: FILTER_KEYS.PRICE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(FILTER_KEYS.TYPE)) {
            const types = filterValue.split(",").map((type: PROPERTY_TYPE) => type);
            if (types.length > 0) {
                filterFromQuery.push({
                    id: FILTER_KEYS.TYPE,
                    type: TFilterType.IN,
                    value: types,
                });
                filterTypesFromQuery.push(types);
            }
        } else if (filterKey.startsWith(FILTER_KEYS.SUB_TYPE)) {
            const types = filterValue.split(",").map((type: PROPERTY_TYPE) => type);
            if (types.length > 0) {
                filterFromQuery.push({
                    id: FILTER_KEYS.TYPE,
                    type: TFilterType.IN,
                    value: types,
                });
                filterSubTypesFromQuery.push(types);
            }
        } else if (filterKey.startsWith(FILTER_KEYS.STATUS)) {
            filterFromQuery.push({
                id: FILTER_KEYS.STATUS,
                type: TFilterType.ENUM,
                value: filterValue,
            });
        } else if (filterKey.startsWith("amenities.")) {
            const amenityKey = filterKey.slice("amenities.".length);
            const id = `amenities.${amenityKey}`;
            filterFromQuery.push({
                id,
                value: filterValue,
                type: TFilterType.BOOLEAN,
            });
        } else if (filterKey.startsWith("features.")) {
            const featureKey = filterKey.slice("features.".length);
            const id = `features.${featureKey}`;
            filterFromQuery.push({
                id,
                value: filterValue,
                type: TFilterType.BOOLEAN,
            });
        } else if (filterKey.startsWith("purpose")) {
            filterFromQuery.push({
                id: "purpose",
                value: filterValue,
                type: TFilterType.STRING,
            });
        } else if (filterKey.startsWith("geozone")) {
            filterFromQuery.push({
                id: "location",
                value: filterValue.split(","),
                type: TFilterType.ZONES,
            });
        }
    });
    return { filterFromQuery };
}

export function getFiltersFromQueryUrl(initialFilters: TFilter[], query: { [key: string]: any }) {
    const initFilters = initialFilters.filter(
        (initialFilter) => initialFilter.id === "purpose" || initialFilter.id === "location",
    );
    const { filterFromQuery } = getFiltersFromQuery(query);
    return initFilters.concat(filterFromQuery);
}

export function getIsCommercialFilter(filter: TFilter[]) {
    return filter.some(
        (filter) =>
            filter.id === "type" &&
            filter.value.length === 1 &&
            filter.value.find((type: PROPERTY_TYPE) => type === PROPERTY_TYPE.commercial),
    );
}
