import { i18nextInstance } from "_common/i18n/IntlProvider";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { useGetItemsForBreadCrumb } from "_common/_utils/hookUtils";
import { PageRendererContext } from "pages/PageContext";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { PageMeta } from "pages/PageMeta";
import { Container } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { ComponentRenderer } from "components/ComponentRenderer";
import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import styles from "./_css/blogPage.module.css";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
    page: IPageMdl;
};

export function BlogPage({ page }: Props) {
    const localizedPage = page.localized[i18nextInstance.language as TLang];
    const { t } = useTranslation();
    const items = useGetItemsForBreadCrumb(page);
    if (!localizedPage || !localizedPage.component) return null;

    const dates = [];
    if (localizedPage.publishedAt) {
        dates.push(
            t(`blog.publishedAt`, {
                count: Number(dayjs(localizedPage.publishedAt).format("D")),
                month: t("dates.monthOfYear." + dayjs(localizedPage.publishedAt).format("M")).toLowerCase(),
                year: dayjs(localizedPage.publishedAt).format("YYYY"),
            }),
        );
    }
    if (localizedPage.editedAt) {
        dates.push(
            t(`blog.editedAt`, {
                count: Number(dayjs(localizedPage.editedAt).format("D")),
                month: t("dates.monthOfYear." + dayjs(localizedPage.editedAt).format("M")).toLowerCase(),
                year: dayjs(localizedPage.editedAt).format("YYYY"),
            }),
        );
    }

    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={page} />
            <div id="blogPage" className={styles.blogContainer}>
                <div className={styles.blogContent}>
                    {dates.length > 0 && (
                        <Container className={"mt_30 mb_20"}>
                            <div className={"ph_5"}>
                                <div className={"ml_8"}>
                                    <Breadcrumb currentItem={{ itemLabel: localizedPage.title }} items={items} />
                                    <div className={clsx(styles.blogDates)}>{dates.join(" | ")}</div>
                                </div>
                            </div>
                        </Container>
                    )}
                    <ComponentRenderer component={localizedPage.component} isRoot />
                </div>
            </div>
        </PageRendererContext>
    );
}
