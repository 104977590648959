import React from "react";
import { AdvancedResearches } from "advancedResearches/AdvancedResearches";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { observer } from "mobx-react";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { useTranslation } from "react-i18next";
import { getSearchResultPageUrlFromGeoZonePurposeAndType } from "_common/_utils/geoZoneUtils";
import { isBuyingPage } from "_common/_utils/pageUtils";
import { useLocation } from "react-router-dom";

export const AdvancedResearchesSRPsPropertyTypes = observer(() => {
    const propertiesStore = usePropertiesStore();
    const { propertyTypeKey } = useSearchPageParams();
    const { t } = useTranslation();

    void geoZonesStore.fetchProvinces();
    const location = useLocation();

    if (!geoZonesStore.provinces) return null;

    const purpose = isBuyingPage(location.pathname) ? "buy" : "rent";

    const translatedType = t(`property.typesUrl.${propertyTypeKey}`);

    const sortedProvinces = [...geoZonesStore.provinces].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <AdvancedResearches
            links={sortedProvinces.map((province) => ({
                to: getSearchResultPageUrlFromGeoZonePurposeAndType(province, propertiesStore.purpose, translatedType),
                label: `${t("advancedResearches." + propertyTypeKey)} ${t(
                    "advancedResearches." + purpose,
                ).toLowerCase()} ${province.name.split(",")[0]}`,
            }))}
        />
    );
});
