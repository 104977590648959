import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { PROPERTY_PURPOSE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { PropertyCardSrps } from "properties/searchBar/featured/PropertyCardSrps";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styles from "./_css/featuredPropertiesSrps.module.css";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { TLang } from "_configs/sharedConfig";

export function FeaturedPropertiesSrps({ properties }: { properties: TPropertyListingMdl[] }) {
    const { t, i18n } = useTranslation();
    const propertiesStore = usePropertiesStore();

    const geoZone = geoZonesStore.geoZone;
    let featuredTitle = "";

    if (geoZone) {
        featuredTitle =
            geoZone.localized?.[i18n.language as TLang]?.[propertiesStore.purpose as PROPERTY_PURPOSE]?.srps
                ?.highlights;
    }

    return (
        <>
            <div className={"flex justifyContent_spaceBetween"}>
                <h2 className={clsx("mb_20 ", styles.title)}>
                    {featuredTitle ||
                        (geoZone
                            ? t("propertyPage.featuresProperties", { geoZoneName: geoZonesStore.zoneName })
                            : t("propertyPage.featuresPropertiesHomePage"))}
                </h2>
                <div className={"flex gap_10"}>
                    <div className="swiper-button-prev-featured"></div>
                    <div className="swiper-button-next-featured"></div>
                </div>
            </div>
            <Swiper
                navigation={{
                    nextEl: `.swiper-button-next-featured`,
                    prevEl: `.swiper-button-prev-featured`,
                }}
                loop
                slidesPerView={"auto"}
                observer
                observeParents
                spaceBetween={10}
                breakpoints={{
                    1400: {
                        slidesPerView: 7,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 6.5,
                        spaceBetween: 20,
                    },
                    1100: {
                        slidesPerView: 5.5,
                        spaceBetween: 20,
                    },
                    960: {
                        slidesPerView: 4.5,
                    },
                    750: {
                        slidesPerView: 4,
                    },
                    550: {
                        slidesPerView: 2.5,
                    },
                    425: {
                        slidesPerView: 2,
                    },
                    375: {
                        slidesPerView: 1.5,
                    },
                    0: {
                        slidesPerView: 1,
                    },
                }}
            >
                {properties.length === 0 && <div className={styles.loading} />}
                {properties.map((property) => {
                    if (!property) return;
                    return (
                        <SwiperSlide key={property._id}>
                            <PropertyCardSrps property={property} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
}
