import React from "react";
import styles from "./_css/header.module.css";
import clsx from "clsx";
import { usePage, useScrollPosition } from "_common/_utils/hookUtils";
import { Logo } from "_common/icons/Logo";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PAGE_REF } from "pages/PageRegular";
import { observer } from "mobx-react";
import { CartButton } from "Cart/CartButton";
import { CartMenu } from "Cart/CartMenu";
import { CartContent } from "Cart/CartContent";
import { Menu } from "main/header/_menu/Menu";
import { MenuMobile } from "main/header/_menu/MenuMobile";
import { LangSelector } from "main/header/_menu/LangSelector";
import i18next from "i18next";
import { KeyboardArrowDown } from "@material-ui/icons";

function HeaderContent() {
    const { isPricePage, isDashboardPage, isRentOrBuyPage } = usePage();

    return (
        <div
            className={clsx("position_relative flex_row_center", styles.container, styles.headerContent, {
                [styles.wrapperDashboardPage]: isDashboardPage,
                ["makeItAContainer"]: !isRentOrBuyPage,
            })}
        >
            <Logo containerClassName={"mh_20"} width={125} />
            <div className={"flex-1 mr_20"}>
                <Menu />
            </div>
            {isPricePage && (
                <>
                    <CartButton />
                    <div className={"mr_20"}>
                        <CartMenu>
                            <CartContent />
                        </CartMenu>
                    </div>
                </>
            )}
        </div>
    );
}

function HeaderMobileContent() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    return (
        <div
            className={clsx(
                "flex_row_center justifyContent_spaceBetween",
                styles.container,
                styles.headerContentMobile,
            )}
        >
            <div className={"flex-1"}>
                <MenuMobile />
            </div>
            <div className={"flex-1"}>
                <Logo containerClassName={"mh_20"} width={125} />
            </div>
            <div className={"flex-1"}>
                <div>
                    <div className={styles.language} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <div>{i18next.language.toUpperCase()}</div>
                        <KeyboardArrowDown />
                    </div>
                    <div
                        className={clsx(styles.containerMobile, {
                            ["hidden"]: !isMenuOpen,
                        })}
                    >
                        <ul>
                            <LangSelector isMobile />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Header = observer(() => {
    const scrollPosition = useScrollPosition(PAGE_REF);
    const { isHomePage, isRentOrBuyPage, isPropertyUnitPage, isDashboardPage } = usePage();

    return (
        <>
            <header
                onClick={() => {
                    geoZonesStore.open(false);
                }}
            >
                <div className={clsx(styles.header)}>
                    <div
                        className={clsx("position_fixed", styles.wrapper, {
                            [styles.wrapperRentOrBuyPage]: isRentOrBuyPage,
                            [styles.wrapperIsProjectPage]: isPropertyUnitPage,
                            [styles.wrapperDashboardPage]: isDashboardPage,
                            [styles.wrapperWithoutBanner]: !isRentOrBuyPage && !isPropertyUnitPage,
                            [styles.wrapperScrollingHomePage]: scrollPosition && scrollPosition > 1000 && isHomePage,
                        })}
                    >
                        <HeaderContent />
                        <HeaderMobileContent />
                    </div>
                </div>
            </header>
            <div
                hidden={isHomePage}
                className={clsx(styles.placeholder, {
                    [styles.placeholderWithoutBanner]: !isRentOrBuyPage && !isPropertyUnitPage,
                })}
            />
        </>
    );
});

export default Header;
