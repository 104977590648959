import React, { RefObject, useEffect, useRef, useState } from "react";
import estatePartner from "../../assets/images/estatePartner.webp";
import styles from "./_css/estatePartner.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useScrollPosition, useWindowSize } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { KeyboardArrowDown } from "@material-ui/icons";

export function EstatePartner() {
    const { height } = useWindowSize();
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const scrollPosition = useScrollPosition(PAGE_REF);
    const ref: RefObject<HTMLDivElement> = useRef(null);
    useEffect(() => {
        if (scrollPosition) {
            setIsVisible(((ref.current as unknown) as HTMLElement)?.offsetTop + 150 - height <= scrollPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollPosition]);
    return (
        <div className={styles.container}>
            <div
                className={clsx("position_relative flex_center_center", styles.content, {
                    [styles.unCollapseHeight]: !isCollapsed,
                })}
                ref={ref}
            >
                <LazyLoadImage
                    src={estatePartner}
                    alt={"image estate partner"}
                    className={clsx(styles.img, { [styles.unCollapseHeight]: !isCollapsed })}
                />
                <div
                    className={clsx("position_absolute flex_center_center", styles.contentText, {
                        ["slideTop50"]: isVisible,
                    })}
                >
                    <div className={clsx(styles.subContent, "flex_column_center justifyContent_spaceBetween")}>
                        <div
                            className={styles.text}
                            dangerouslySetInnerHTML={{ __html: t("homePage.estatePartner.text1") }}
                        />
                        <div className={clsx({ [styles.hiddenMobile]: isCollapsed })}>
                            <h2 className={styles.title}> {t("homePage.estatePartner.title")} </h2>
                            <div
                                className={clsx(styles.text, "mb_25")}
                                dangerouslySetInnerHTML={{ __html: t("homePage.estatePartner.text2") }}
                            />
                        </div>
                        {isCollapsed && (
                            <div
                                className={clsx(
                                    styles.text,
                                    styles.hiddenDesktop,
                                    "flex_row_center mt_20 cursor_pointer",
                                )}
                                onClick={() => setIsCollapsed(false)}
                            >
                                {t("propertyPage.seeMore")}
                                <KeyboardArrowDown />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
