import React from "react";
import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { NOTIFICATION_TYPE, notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TContactPromoteForm } from "payments/_components/PromoteForm";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { ButtonNotification } from "components/misc/notifications/ButtonNotificationProps";

type Props = {
    type?: NOTIFICATION_TYPE;
    successMessage?: boolean;
    successToast?: boolean;
    onSuccess?: () => void;
    id?: string;
};

export const ContactFormBtn = observer((props: Props) => {
    const { t } = useTranslation();

    const { handleSubmit, errors } = useFormContext();

    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";

    const hasError = Object.keys(errors).length > 0;

    if (props.successToast && loading?.isSucceeded) {
        toast.success("Message envoyé avec succès !");
    }
    if (loading?.isSucceeded && props.onSuccess) {
        props.onSuccess();
    }

    return (
        <div>
            {props.successMessage && loading?.isSucceeded && <div>{t("contactPage.succeededSend")}</div>}
            {(hasError || loading?.error) && (
                <div>
                    {Object.entries(errors).map(([key, value]) => {
                        return (
                            <div className={"text_error"} key={value.message}>
                                {t("words." + key)} {">"} {value.message}
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="flex_row mt_10">
                <ButtonNotification
                    id={props.id}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleSubmit((data) => {
                        if (!isLoading) {
                            switch (props.type) {
                                case NOTIFICATION_TYPE.CONTACT_PROMOTE:
                                    setPromise(notifierStore.contactFromPromote(data as TContactPromoteForm));
                                    break;
                                default:
                                    setPromise(notifierStore.contact(data as TContactForm));
                            }
                        }
                    })}
                >
                    {isLoading ? t("loaders.message") : t("words.submit")}
                </ButtonNotification>
            </div>
        </div>
    );
});
