import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { observer } from "mobx-react";
import { TAskForVisitOrInfoForm } from "properties/propertyPage/contact-form/property-or-unit-contact-form";
import { useUnitStore } from "units/UnitContext";
import i18next from "i18next";
import styles from "../_css/propertyAppointmentBtn.module.css";
import { SIGN_UP_TYPE } from "users/_models/UserMdl";
import { ButtonNotification } from "components/misc/notifications/ButtonNotificationProps";
import { userStore } from "users/_stores/userStore";
import { ArrowCtaIcon } from "properties/propertyPage/_components/ArrowCtaIcon";
import clsx from "clsx";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";

type Props = {
    hasAgreedNotification?: boolean;
};

export const PropertyOrUnitContactFormBtn = observer((props: Props) => {
    const history = useHistory();
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const { user, isLogged } = userStore;
    const _hasAgreedNotification = !!props.hasAgreedNotification;
    const { handleSubmit, errors } = useFormContext<TAskForVisitOrInfoForm>();
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";
    const { t } = useTranslation();
    const errorMessage = errors.message?.message || errors.email?.message || errors.phone?.message || "";
    return (
        <>
            {loading?.isSucceeded && <div id={"successRequest"}>{t("contactPage.succeededSend")}</div>}
            {(errorMessage || loading?.error) && <ErrorBlock error={errorMessage ?? loading?.error} />}
            <div className="flex_row">
                <ButtonNotification
                    color={"primary"}
                    disabled={loading?.isSucceeded && !isLoading}
                    variant={"contained"}
                    className={styles.btn}
                    onClick={handleSubmit<TAskForVisitOrInfoForm>(async (data) => {
                        const notifierPromise = notifierStore.askForInfo({
                            ...(data as TAskForVisitOrInfoForm),
                            lang: i18next.language,
                            signUpType: SIGN_UP_TYPE.FORM_CTA,
                            signUpFrom: propertyStore.property.purpose,
                            propertyId: propertyStore.property._id,
                            notification: _hasAgreedNotification,
                            ...(unitStore?.unit?._id && { unitId: unitStore?.unit?._id }),
                            ...(user && isLogged && { userId: user?._id }),
                        });
                        setPromise(notifierPromise);
                        notifierPromise.then(() => {
                            history.push({
                                pathname: URLS.requestSuccess(propertyStore.property.localized.urlAlias),
                                search: `?type=${propertyStore.property.type}&purpose=${propertyStore.property.purpose}`,
                            });
                        });
                    })}
                >
                    <div className={clsx("mr_10")}>
                        {isLoading ? t("loaders.message") : t("propertyPage.contact-form.send")}
                    </div>
                    <ArrowCtaIcon />
                </ButtonNotification>
            </div>
        </>
    );
});
