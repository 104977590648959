import { PARAMS_NORTH_PREFIX, PARAMS_PAGE_PREFIX, PARAMS_ZOOM_PREFIX } from "_common/_utils/searchUtils";
import { isPropertyTypeOrSubType } from "properties/searchBar/filters/_utils/filtersUtils";
import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls, removeAccentFromString } from "_common/_utils/alphaNumUtils";
import { TLang } from "_configs/sharedConfig";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

export function getPatternOfGeoZone(urlSplit: string[], lang: TLang) {
    const isProvinceOrCityOrNeighborhoodPagePattern =
        urlSplit.length >= 1 &&
        urlSplit[0].length === 2 &&
        !urlSplit[0].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[0].startsWith(PARAMS_ZOOM_PREFIX) &&
        !urlSplit[0].startsWith(PARAMS_PAGE_PREFIX) &&
        !urlSplit[0].startsWith(reformatStringForUrls(getI18nExpByLang(lang, "property.featuresUrls.indoorParking"))) &&
        !isPropertyTypeOrSubType(urlSplit[0], lang);

    const isCityOrNeighborhoodPagePattern =
        isProvinceOrCityOrNeighborhoodPagePattern &&
        urlSplit.length >= 2 &&
        !urlSplit[1].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[1].startsWith(PARAMS_ZOOM_PREFIX) &&
        !urlSplit[1].startsWith(PARAMS_PAGE_PREFIX) &&
        !isPropertyTypeOrSubType(urlSplit[1], lang) &&
        !urlSplit[1].startsWith(reformatStringForUrls(getI18nExpByLang(lang, "property.featuresUrls.indoorParking"))) &&
        urlSplit[1] !== "";
    const isNeighborhoodPagePattern =
        isCityOrNeighborhoodPagePattern &&
        urlSplit.length >= 3 &&
        !isPropertyTypeOrSubType(urlSplit[2], lang) &&
        !urlSplit[2].startsWith(PARAMS_ZOOM_PREFIX) &&
        !urlSplit[2].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[2].startsWith(reformatStringForUrls(getI18nExpByLang(lang, "property.featuresUrls.indoorParking"))) &&
        !urlSplit[2].startsWith(PARAMS_PAGE_PREFIX);
    return { isProvinceOrCityOrNeighborhoodPagePattern, isCityOrNeighborhoodPagePattern, isNeighborhoodPagePattern };
}

export function getSearchResultPageUrlFromGeoZonePurposeAndType(
    geoZone?: Pick<TGeoZoneMdl, "type" | "address">,
    purpose: PROPERTY_PURPOSE = PROPERTY_PURPOSE.BUY,
    type = "",
    lang?: TLang,
    additionalParam?: string,
) {
    let searchResultUrlFunc = URLS.buy;
    if (purpose === PROPERTY_PURPOSE.RENT) searchResultUrlFunc = URLS.rent;

    if (!geoZone) {
        return searchResultUrlFunc(lang, type, additionalParam);
    } else if (geoZone.type === GEO_ZONE_TYPE.PROVINCE) {
        return searchResultUrlFunc(
            lang,
            removeAccentFromString(geoZone.address.province).replace(" ", "").toLowerCase(),
            type,
            additionalParam,
        );
    } else if (geoZone.type === GEO_ZONE_TYPE.CITY) {
        return searchResultUrlFunc(
            lang,
            removeAccentFromString(geoZone.address.province).replace(" ", "").toLowerCase(),
            reformatStringForUrls(geoZone.address.city),
            type,
            additionalParam,
        );
    } else if (geoZone.type === GEO_ZONE_TYPE.NEIGHBORHOOD) {
        return searchResultUrlFunc(
            lang,
            removeAccentFromString(geoZone.address.province).replace(" ", "").toLowerCase(),
            reformatStringForUrls(geoZone.address.city),
            reformatStringForUrls(geoZone.address.neighbourhood),
            type,
            additionalParam,
        );
    }
    return searchResultUrlFunc();
}

export function zoneName(geoZone: TGeoZoneMdl) {
    return geoZone.name.split(",")?.[0] || geoZone.address?.neighbourhood || geoZone.address?.city;
}
