import React from "react";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PropertiesBreadcrumbContent } from "properties/listing/PropertiesBreadcrumbContent";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";

export function PropertiesBreadcrumb() {
    const { geoZone } = geoZonesStore;
    if (!geoZone || geoZone.type === "PROVINCE") return null;

    return <_PropertiesBreadcrumb />;
}

function _PropertiesBreadcrumb() {
    const { geoZone } = geoZonesStore;
    const loadingState = geoZonesStore.fetchParentGeoZones(geoZone!._id);

    return (
        <LoadableFromLoading
            loading={loadingState}
            renderer={() => {
                return <PropertiesBreadcrumbContent />;
            }}
        />
    );
}
