import React, { ReactNode } from "react";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./_css/breadcrumb.module.css";
import clsx from "clsx";
import { refreshPage } from "_common/_utils/pageUtils";
import { IconComponent } from "components/base/icon/IconComponent";

export type TBreadcrumbCurrentItem = {
    itemLabelKey?: string;
    itemLabel?: string;
};

export type TBreadcrumbItem = TBreadcrumbCurrentItem & {
    url?: string;
    forceReload?: boolean;
    pageTitle?: string;
    icon?: ReactNode;
};

type Props = {
    currentItem: TBreadcrumbCurrentItem;
    items?: TBreadcrumbItem[];
    noHome?: boolean;
};

export function Breadcrumb(props: Props) {
    const { t } = useTranslation();
    let isBlog = false;
    if (props.items && props.items?.[0]?.url?.split("/")[2] === "blog") {
        isBlog = true;
    }
    return (
        <>
            <div className={clsx(styles.container, "mb_10")}>
                <ul className={clsx("container flexWrap_wrap")}>
                    {!props.noHome && (
                        <li>
                            <Link to={URLS.home()}>
                                <IconComponent icon="icon-home" />
                            </Link>
                        </li>
                    )}
                    {props.items &&
                        props.items.map((item, idx) => (
                            <li key={idx}>
                                <Link
                                    onClick={(e) => {
                                        if (item.forceReload && item.url) {
                                            e.preventDefault();
                                            refreshPage(item.url);
                                        }
                                    }}
                                    to={item.url ?? ""}
                                >
                                    {item.icon ?? item.itemLabel ?? t(item.itemLabelKey ?? "")}
                                </Link>
                            </li>
                        ))}
                    <li className={clsx({ [styles.isBlog]: isBlog })}>
                        {props.currentItem.itemLabel ?? t(props.currentItem.itemLabelKey ?? "")}
                    </li>
                </ul>
            </div>
        </>
    );
}
