import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertiesList } from "virtualAssistant/PropertiesList";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";

// askForInfo, askForVisit
export function RequestSuccessListing({ property, geoZone }: { property: TPropertyListingMdl; geoZone: TGeoZoneMdl }) {
    const filters = [
        {
            id: "purpose",
            value: property.purpose,
            type: TFilterType.STRING,
        },
        {
            id: "type",
            value: property.type,
            type: TFilterType.STRING,
        },
        {
            id: "location",
            value: [geoZone._id],
            type: TFilterType.ZONES,
        },
        {
            id: "_id",
            value: property._id,
            type: TFilterType.NE,
        },
    ];

    const listStore = propertiesStore.getListStore("similar-properties", undefined, filters);

    listStore.load();

    // TODO: quick and ugly fix [propertyIdToHide]
    return (
        <LoadableFromLoading
            loading={listStore.currentLoadingState}
            renderer={(_status, error) => {
                if (error) return null;
                return <PropertiesList propertyIdToHide={property._id} listStore={listStore} />;
            }}
        />
    );
}
