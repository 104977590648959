import React, { useState } from "react";
import { Meta } from "_common/_utils/Meta";
import { URLS } from "_configs/URLS";
import clsx from "clsx";
import { removeInlineCssForMceText } from "_common/_utils/alphaNumUtils";
import { usePropertiesPageMeta } from "properties/usePropertiesPageMeta";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import styles from "../_css/propertiesViewHeader.module.css";
import { KeyboardArrowDown } from "@material-ui/icons";
import { observer } from "mobx-react";

export const PropertiesViewHeader = observer(() => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);

    const [descAndLinksHidden, setDescAndLinksHidden] = useState(true);

    const { isCityPage, isExistingPropertyType, propertyTypeLocalized } = useSearchPageParams();
    const { title, metaTitle, seoText, metaDescription } = usePropertiesPageMeta(propertiesStore);
    const noIndex =
        (isCityPage && !geoZonesStore.geoZone) || (propertyTypeLocalized && !isExistingPropertyType && isCityPage);
    return (
        <>
            <Meta title={metaTitle} description={metaDescription} url={noIndex ? URLS.home() : undefined} />
            <div className={clsx("flex flex_row_center justifyContent_spaceBetween", styles.titleSection)}>
                <h1
                    onClick={() => setDescAndLinksHidden(!descAndLinksHidden)}
                    className={clsx(styles.title, "flex_row alignItems_center")}
                >
                    {title}
                    <span className={"hiddenMediumMobile flex_row_center"}>
                        <KeyboardArrowDown
                            fontSize={"large"}
                            className={clsx({ [styles.openArrow]: !descAndLinksHidden })}
                        />
                    </span>
                </h1>
            </div>
            {listStore.selectedPage === 1 && (
                <div className={clsx("mb_20", styles.textHidden)} hidden={descAndLinksHidden}>
                    <div dangerouslySetInnerHTML={{ __html: removeInlineCssForMceText(seoText) }} />
                </div>
            )}
        </>
    );
});
