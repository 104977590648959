import React, { RefObject, useRef } from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { PriceFilter } from "properties/searchBar/filters/PriceFilter";
import { PropertyTypeFilter } from "properties/searchBar/filters/PropertyTypeFilter";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { useHistory } from "react-router";
import clsx from "clsx";
import styles from "./_css/filters.module.css";
import {
    FILTER_KEYS,
    getFeaturesOrAmenitiesFilters,
    getFiltersValueKey,
    getIsCommercialFilter,
    getLabelFilterValue,
} from "properties/searchBar/filters/_utils/filtersUtils";
import { observer } from "mobx-react";
import { settingsStore } from "settings/_stores/settingsStore";
import { KeyboardArrowDown } from "@material-ui/icons";
import { RoomsNumberFilter } from "properties/searchBar/filters/RoomsFIlter";
import { MoreFilter } from "properties/searchBar/filters/MoreFilters";
import { SavedSearchButton } from "properties/searchBar/savedSearchButton";
import { useClickOutside } from "_common/_utils/hookUtils";
import IconButton from "@material-ui/core/IconButton";
import cross from "../../assets/images/crossFilters.svg";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

export const FILTERS = [
    {
        key: "type",
        filters: [FILTER_KEYS.TYPE, FILTER_KEYS.SUB_TYPE],
        labelKey: "searchBar.filter.propertyType",
        filterComponent: PropertyTypeFilter,
    },
    {
        key: "price",
        filters: [FILTER_KEYS.PRICE_MIN, FILTER_KEYS.PRICE_MAX],
        labelKey: "searchBar.filter.price",
        filterComponent: PriceFilter,
    },
    {
        key: "rooms",
        filters: [
            FILTER_KEYS.BEDROOMS_MIN,
            FILTER_KEYS.BEDROOMS_MAX,
            FILTER_KEYS.BATHROOMS_MIN,
            FILTER_KEYS.BATHROOMS_MAX,
        ],
        labelKey: "searchBar.filter.rooms",
        filterComponent: RoomsNumberFilter,
    },
    {
        key: "more",
        filters: [
            FILTER_KEYS.SQUARE_SURFACE_MIN,
            FILTER_KEYS.SQUARE_SURFACE_MAX,
            FILTER_KEYS.STATUS,
            FILTER_KEYS.FEATURES,
            FILTER_KEYS.AMENITIES,
        ],
        labelKey: "searchBar.filter.more",
        filterComponent: MoreFilter,
    },
];

export const Filters = observer(() => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const history = useHistory();
    const isCommercialFilter = getIsCommercialFilter(listStore.filters);
    const containerRef: RefObject<HTMLDivElement> = useRef(null);

    useClickOutside(
        containerRef,
        () => {
            setTimeout(() => {
                settingsStore.close();
            }, 100);
        },
        "MuiAutocomplete-option",
    );
    return (
        <div ref={containerRef} className={"flex-1 flex_row_center position_relative gap_20"}>
            <div className={clsx(styles.containerBtn, "flex_row_center")}>
                {FILTERS.map((filter) => {
                    const ComponentTag = filter.filterComponent;
                    if (isCommercialFilter && filter.key === "rooms") return null;
                    const filteredValueKey = getFiltersValueKey(listStore.filters, filter.filters);
                    const filteredLabel = filteredValueKey?.length
                        ? getLabelFilterValue(filteredValueKey[0], propertiesStore.purpose ?? PROPERTY_PURPOSE.BUY)
                        : undefined;
                    return (
                        <div key={filter.key}>
                            <UiButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (settingsStore.filterOpened !== filter.key) {
                                        settingsStore.setFilterIsOpened(filter.key);
                                    } else {
                                        settingsStore.close();
                                    }
                                }}
                                color={"primary"}
                                className={clsx(styles.button, {
                                    [styles.btnOpen]: settingsStore.filterOpened === filter.key,
                                })}
                                variant={"outlined"}
                                type={"button"}
                            >
                                {filteredLabel ?? t(filter.labelKey)}
                                {filteredLabel ? (
                                    <IconButton
                                        aria-label="close"
                                        size={"small"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (filter.key === "more") {
                                                const filters = getFeaturesOrAmenitiesFilters(listStore.filters);
                                                for (const filter of filters) {
                                                    listStore.removeFilterByFilterId(filter.id);
                                                }
                                            }
                                            filter.filters.forEach((filterKey) => {
                                                listStore.removeFilterByFilterId(filterKey);
                                                fetchSearchProperties(propertiesStore, listStore, history, true);
                                            });
                                        }}
                                        className={clsx("ml_5", styles.chevron, styles.cross)}
                                    >
                                        <img src={cross} alt={"cross"} />
                                    </IconButton>
                                ) : (
                                    <KeyboardArrowDown
                                        className={clsx("ml_5", styles.chevron, {
                                            [styles.chevronOpened]: settingsStore.filterOpened === filter.key,
                                        })}
                                        fontSize={"default"}
                                    />
                                )}
                            </UiButton>
                            {settingsStore.filterOpened === filter.key && (
                                <div
                                    className={clsx("position_absolute", styles.subMenuContainer, {
                                        [styles.subMenuOpened]: settingsStore.filterOpened === filter.key,
                                    })}
                                >
                                    <ComponentTag listStore={listStore} filter={filter} />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <SavedSearchButton />
        </div>
    );
});
