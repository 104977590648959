import React, { RefObject, useImperativeHandle, useRef, useState } from "react";
import {
    PROPERTY_PURPOSE,
    PROPERTY_SUBTYPE,
    PROPERTY_SUBTYPE_CONFIG,
    PROPERTY_TYPE,
    PROPERTY_TYPE_BY_PURPOSE,
    TPropertyListingMdl,
} from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { ListStore } from "_common/list/ListStore";
import { useHistory } from "react-router";
import { FILTER_KEYS, updateSearchFilter } from "properties/searchBar/filters/_utils/filtersUtils";
import clsx from "clsx";
import styles from "./_css/propertyTypeFilter.module.css";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { observer } from "mobx-react";
import { Checkbox } from "@material-ui/core";
import { FilterButtons } from "properties/searchBar/filters/FilterButtons";
import { ExposedFiltersFunctions } from "properties/searchBar/MobileFilters";
import { useClickOutside } from "_common/_utils/hookUtils";
import { propertyTypesToFilter } from "properties/searchBar/filters/_utils/propertyTypeUtils";
import { TFilter } from "admin/_common/filters/TFilter";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
    filter: {
        key: string;
        labelKey: string;
    };
};

const _PropertyTypeFilter = React.forwardRef<ExposedFiltersFunctions, Props>((props: Props, ref) => {
    const { t } = useTranslation();
    const history = useHistory();
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    const listStore = props.listStore;
    const propertiesStore = usePropertiesStore();
    const currentTypesFilter = listStore.filters?.[listStore.getFilterIndex(FILTER_KEYS.TYPE)];
    const currentSubTypeFilter = listStore.filters?.[listStore.getFilterIndex(FILTER_KEYS.SUB_TYPE)];
    const initialPropertyTypes =
        currentTypesFilter && currentTypesFilter.value && currentTypesFilter.value.length > 0
            ? JSON.parse(JSON.stringify(currentTypesFilter.value))
            : [];

    const initialPropertySubTypes =
        currentSubTypeFilter && currentSubTypeFilter.value && currentSubTypeFilter.value.length > 0
            ? JSON.parse(JSON.stringify(currentSubTypeFilter.value))
            : [];

    const [propertyTypes, setPropertyTypes] = useState<PROPERTY_TYPE[]>(initialPropertyTypes);
    const [propertySubTypes, setPropertySubTypes] = useState<PROPERTY_SUBTYPE[]>(initialPropertySubTypes);
    const propertyTypeFiltered = PROPERTY_TYPE_BY_PURPOSE[propertiesStore.purpose ?? PROPERTY_PURPOSE.BUY];
    const propertySubTypeFiltered = propertyTypes[0] ? PROPERTY_SUBTYPE_CONFIG[propertyTypes[0]] : [];
    function togglePropertyType(propertyType: PROPERTY_TYPE) {
        const currentPropertyType = [...propertyTypes];
        if (currentPropertyType.length === propertyTypeFiltered.length) setPropertyTypes([]);
        const typeIndex = currentPropertyType.indexOf(propertyType);
        if (typeIndex > -1) {
            currentPropertyType.splice(typeIndex, 1);
        } else {
            currentPropertyType.push(propertyType);
        }
        if (currentPropertyType.length === propertyTypeFiltered.length) {
            currentPropertyType.splice(0, currentPropertyType.length);
        }
        setPropertyTypes(currentPropertyType);
        setPropertySubTypes([]);
    }

    function togglePropertySubType(propertySubType: PROPERTY_SUBTYPE) {
        const currentSubTypes = [...propertySubTypes];
        const typeIndex = currentSubTypes.indexOf(propertySubType);
        if (typeIndex > -1) {
            currentSubTypes.splice(typeIndex, 1);
        } else {
            currentSubTypes.push(propertySubType);
        }
        if (currentSubTypes.length === propertySubTypeFiltered.length) {
            currentSubTypes.splice(0, currentSubTypes.length);
        }
        setPropertySubTypes(currentSubTypes);
    }

    const applyFilters = () => {
        if (
            JSON.stringify(initialPropertyTypes) === JSON.stringify(propertyTypes) &&
            JSON.stringify(initialPropertySubTypes) === JSON.stringify(propertySubTypes)
        ) {
            return;
        }
        listStore.removeFilterByFilterId(FILTER_KEYS.TYPE);
        listStore.removeFilterByFilterId(FILTER_KEYS.SUB_TYPE);
        const filters: TFilter[] = [];
        filters.push(...propertyTypesToFilter(propertyTypes, FILTER_KEYS.TYPE));
        filters.push(...propertyTypesToFilter(propertySubTypes, FILTER_KEYS.SUB_TYPE));
        updateSearchFilter(listStore, filters, true).then(() => {
            fetchSearchProperties(propertiesStore, props.listStore, history);
        });
    };

    const resetFilters = () => {
        setPropertyTypes([]);
        applyFilters();
    };
    // for trigger filter on mobile with btn on parent
    useImperativeHandle(
        ref,
        () => ({
            applyFilters,
            resetFilters,
        }),
        [propertyTypes],
    );
    // for trigger filter on close filter
    useClickOutside(
        containerRef,
        () => {
            applyFilters();
        },
        "MuiAutocomplete-option",
    );

    const getDisplayType = (propertyType: PROPERTY_TYPE) => {
        if (propertyType === PROPERTY_TYPE.condo && propertiesStore.purpose === PROPERTY_PURPOSE.BUY) {
            return t("property.types.condoWithoutApartment");
        }
        return t(`property.types.${propertyType}`);
    };

    return (
        <div className={clsx(styles.container, "flex_column position_relative")} ref={containerRef}>
            <div className={clsx(styles.title, "p_15")}>{t("searchBar.filter.propertyType")}</div>
            <div>
                {propertyTypeFiltered.map((propertyType) => {
                    return (
                        <div
                            key={propertyType}
                            className={clsx(
                                styles.checkbox,
                                "flex_row_center justifyContent_spaceBetween cursor_pointer pl_40 pr_10",
                            )}
                            onClick={() => togglePropertyType(propertyType)}
                        >
                            <div>{getDisplayType(propertyType)}</div>
                            <Checkbox className={styles.btn} checked={propertyTypes.includes(propertyType)} />
                        </div>
                    );
                })}
            </div>
            {!!propertySubTypeFiltered.length && (
                <>
                    <div className={clsx(styles.title, "p_15")}>{t("searchBar.filter.propertySubType")}</div>
                    <div>
                        {propertySubTypeFiltered.map((propertyType) => {
                            return (
                                <div
                                    key={propertyType}
                                    className={clsx(
                                        styles.checkbox,
                                        "flex_row_center justifyContent_spaceBetween cursor_pointer pl_40 pr_10",
                                    )}
                                    onClick={() => togglePropertySubType(propertyType)}
                                >
                                    <div>{t(`property.subTypes.${propertyType}`)}</div>
                                    <Checkbox
                                        className={styles.btn}
                                        checked={propertySubTypes.includes(propertyType)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
            <FilterButtons className={"p_15"} onReset={resetFilters} onApply={applyFilters} />
        </div>
    );
});

export const PropertyTypeFilter = observer(_PropertyTypeFilter);
