export type TSubject = {
    [lang in string]: string[];
};

export const CONTACT_SUBJECTS: TSubject = {
    fr: [
        "Recherche de logement à louer",
        "Recherche de propriété résidentielle à vendre",
        "Recherche d'espace commercial à louer",
        "Recherche de bâtisse commerciale à vendre",
        "Recherche de terrain à vendre",
        "Annoncer une propriété ou un terrain sur Vistoo",
        "Question concernant mon annonce en ligne",
        "Autres questions générales",
    ],
    en: [
        "Looking for a rental property",
        "Looking for a property for sale",
        "Looking for a commercial space for rent",
        "Looking for a commercial building for sale",
        "Looking for land for sale",
        "Advertise a property or land on Vistoo",
        "Question about my online listing",
        "Other general inquiries",
    ],
    es: [
        "Busco una propiedad en alquiler",
        "Busco una propiedad en venta",
        "Busco un espacio comercial en alquiler",
        "Busco un edificio comercial en venta",
        "Busco un terreno en venta",
        "Anunciar una propiedad o terreno en Vistoo",
        "Pregunta sobre mi anuncio en línea",
        "Otras consultas generales",
    ],
    zh: [
        "寻找出租房产",
        "寻找待售房产",
        "寻找出租商业空间",
        "寻找待售商业建筑",
        "寻找待售土地",
        "在 Vistoo 上发布房产或土地广告",
        "关于我的在线广告的疑问",
        "其他一般咨询",
    ],
};
