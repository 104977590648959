import { TFilter } from "admin/_common/filters/TFilter";
import { FILTER_KEYS } from "properties/searchBar/filters/_utils/filtersUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

export function propertyTypesToFilter(propertyTypes: string[], type: FILTER_KEYS.TYPE | FILTER_KEYS.SUB_TYPE) {
    const filters: TFilter[] = [];
    if (propertyTypes.length) {
        filters.push({
            id: type,
            type: TFilterType.IN,
            value: propertyTypes,
        });
    }
    return filters;
}

export function propertyTypeKeyFromUrl(propertyType: string, i18n: any): string | undefined {
    const labelKeysValues = (getI18nExpByLang(i18n.language, `property.typesUrl`) as unknown) as {
        [key: string]: string;
    };
    return Object.entries(labelKeysValues).find(([key, value]) => (value === propertyType ? key : undefined))?.[0];
}

export function propertySubTypeKeyFromUrl(propertyType: string, i18n: any): string | undefined {
    const labelKeysValues = (getI18nExpByLang(i18n.language, `property.subTypesUrl`) as unknown) as {
        [key: string]: string;
    };
    return Object.entries(labelKeysValues).find(([key, value]) => (value === propertyType ? key : undefined))?.[0];
}
