import React from "react";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "shared/_common/i18n/IntlProvider";
import { pagesStore } from "pages/_stores/pagesStore";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { unitsStore } from "units/_stores/unitsStore";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import * as H from "history";
import { getI18nExpByLang, getPageAliasUrlForBlogPage } from "_common/_utils/pageUtils";
import { PARAMS_NORTH_PREFIX, PARAMS_PAGE_PREFIX, PARAMS_ZOOM_PREFIX } from "_common/_utils/searchUtils";
import { useIsSuccessPage, usePage } from "_common/_utils/hookUtils";
import { useHistory, useLocation } from "react-router-dom";
import i18next from "i18next";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { PAGE_TYPE, TLocalized } from "pages/_models/PageMdl";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import clsx from "clsx";

function changeLang(pathname: string, lang: TLang, history: H.History, isBlogPage = false) {
    const oldLang = i18nextInstance.language as TLang;
    if (oldLang === lang) return;
    const langConfig = sharedConfig.languages[lang];
    // await i18nextInstance.changeLanguage(lang);
    const { hash, search } = history.location;
    if (pathname === "/") {
        window.location.href = langConfig.baseUrl;
    } else {
        const page = pagesStore.getByUrlSync(pathname, oldLang);
        if (page?.localized?.[lang]?.url) {
            const tail = page.localized[lang]?.url + search + hash;
            if (page?.type === PAGE_TYPE.ARTICLE) {
                window.location.href = langConfig.baseUrl + "/" + getI18nExpByLang(oldLang, "routes.blog") + tail;
            } else window.location.href = langConfig.baseUrl + tail;
        } else if (!page && pathname.split("/").length >= 3) {
            const oldTranslatedRoute = pathname.split("/")[2];
            // @ts-expect-error
            const route = Object.entries(i18nextInstance.getDataByLanguage(oldLang)?.translation.routes).find(
                ([_key, value]) => value === oldTranslatedRoute,
            );
            if (route && route[0]) {
                const arrNewPathname = [...pathname.split("/")];
                const isPropertyOrUnitPattern = route[0] === "properties";
                if (isPropertyOrUnitPattern) {
                    const isUnitPagePattern = arrNewPathname.length > 4;
                    if (isUnitPagePattern) {
                        const unitOrFetch = unitsStore.getByAliasUrl(
                            arrNewPathname[5],
                            arrNewPathname[3],
                            oldLang,
                            lang,
                        );
                        if (unitOrFetch && "_id" in unitOrFetch) {
                            window.location.href = `/${lang}/${getI18nExpByLang(lang, "routes.properties")}/${
                                unitOrFetch.property.localized.urlAlias
                            }/${getI18nExpByLang(lang, "routes.units")}/${unitOrFetch.localized.urlAlias}`;
                        } else {
                            unitOrFetch?.then(
                                // @ts-expect-error
                                (unit: TUnitListingMdl) => {
                                    window.location.href = `/${lang}/${getI18nExpByLang(lang, "routes.properties")}/${
                                        unit.property.localized.urlAlias
                                    }/${getI18nExpByLang(lang, "routes.units")}/${unit.localized.urlAlias}`;
                                },
                                () => {
                                    window.location.href = langConfig.baseUrl;
                                },
                            );
                        }
                    } else {
                        ((propertiesStore.getByAliasUrl(arrNewPathname[3], oldLang, lang) as unknown) as Promise<{
                            data: TPropertyListingMdl;
                        }>).then(
                            async ({ data: property }) => {
                                window.location.href = `/${lang}/${getI18nExpByLang(lang, "routes.properties")}/${
                                    property.localized.urlAlias
                                }`;
                            },
                            () => {
                                window.location.href = langConfig.baseUrl;
                            },
                        );
                    }
                } else {
                    arrNewPathname[2] = getI18nExpByLang(lang, `routes.${route[0]}`);
                    // Care, in test we supposed propertyType.length > 3 and province <= 3
                    if (
                        arrNewPathname?.[3] &&
                        arrNewPathname?.[3].length > 3 &&
                        (!arrNewPathname[3].startsWith(PARAMS_PAGE_PREFIX) ||
                            !arrNewPathname[3].startsWith(PARAMS_ZOOM_PREFIX) ||
                            !arrNewPathname[3].startsWith(PARAMS_NORTH_PREFIX))
                    ) {
                        const propertyType = Object.keys(PROPERTY_TYPE).find(
                            (propertyType) =>
                                reformatStringForUrls(
                                    getI18nExpByLang(oldLang, "property.typesUrl." + propertyType),
                                ) === arrNewPathname[3].toLowerCase(),
                        );
                        if (propertyType) {
                            arrNewPathname[3] = reformatStringForUrls(
                                getI18nExpByLang(lang, "property.typesUrl." + propertyType),
                            );
                        }
                    } else if (
                        arrNewPathname?.[5] &&
                        (!arrNewPathname[5].startsWith(PARAMS_PAGE_PREFIX) ||
                            !arrNewPathname[5].startsWith(PARAMS_ZOOM_PREFIX) ||
                            !arrNewPathname[5].startsWith(PARAMS_NORTH_PREFIX))
                    ) {
                        const propertyType = Object.keys(PROPERTY_TYPE).find(
                            (propertyType) =>
                                reformatStringForUrls(
                                    getI18nExpByLang(oldLang, "property.typesUrl." + propertyType),
                                ) === arrNewPathname[5].toLowerCase(),
                        );
                        if (propertyType) {
                            arrNewPathname[5] = reformatStringForUrls(
                                getI18nExpByLang(lang, "property.typesUrl." + propertyType),
                            );
                        }
                    }
                    const newPathname = arrNewPathname.join("/");
                    window.location.href =
                        langConfig.baseUrl + newPathname.substr(langConfig.basePath.length) + search + hash;
                }
            } else {
                window.location.href = langConfig.baseUrl;
            }
        } else if (isBlogPage) {
            window.location.href = langConfig.baseUrl + "/" + getI18nExpByLang(oldLang, "routes.blog");
        } else {
            window.location.href = langConfig.baseUrl;
        }
    }
}

function getLanguagesFromCustomPage(pathname: string) {
    const displayLang: TLang[] = [];
    if (
        !window.__PRELOADED_STATE__?.r?.pages?.items ||
        window.__PRELOADED_STATE__?.r?.pages?.items[0].localized[i18next.language].url !== pathname.substr(3)
    ) {
        return Object.keys(sharedConfig.languages);
    } else {
        Object.entries(
            window.__PRELOADED_STATE__?.r.pages.items[0].localized as { [key in TLang]: TLocalized },
        ).forEach(([lang, value]) => {
            if (value.published) {
                displayLang.push(lang as TLang);
            }
        });
        return displayLang;
    }
}

export function LangSelector({ isMobile }: { isMobile?: boolean }) {
    const { isHomePage, isBlogPage, isRentOrBuyPage } = usePage();
    const history = useHistory();
    const isSuccessPage = useIsSuccessPage();
    let { pathname } = useLocation();
    pathname = isBlogPage ? getPageAliasUrlForBlogPage(pathname) : pathname;
    let displayLanguages = Object.keys(sharedConfig.languages);

    if (__BROWSER__ && !isHomePage) {
        displayLanguages = getLanguagesFromCustomPage(pathname);
    }
    if (isSuccessPage) {
        displayLanguages = [i18next.language];
    }
    if (isRentOrBuyPage) {
        if (
            Object.values(getI18nExpByLang(i18next.language, "property.featuresUrls")).some((value) =>
                pathname.includes(reformatStringForUrls(value)),
            ) ||
            Object.values(getI18nExpByLang(i18next.language, "property.subTypesUrl")).some((value) =>
                pathname.includes(value),
            )
        ) {
            //si l'url contient garage ou sous type => juste fr
            displayLanguages = [sharedConfig.languages.fr.lang];
        }
    }

    return (
        <>
            {isMobile ? (
                <>
                    {Object.entries(sharedConfig.languages)
                        .filter(([lang]) => displayLanguages.includes(lang))
                        .map(([lang, { title }]) => (
                            <li key={lang}>
                                <ExternalOrNavLink
                                    url={"#"}
                                    onClick={() => {
                                        changeLang(pathname, lang as TLang, history, isBlogPage);
                                    }}
                                >
                                    {title}
                                </ExternalOrNavLink>
                            </li>
                        ))}
                </>
            ) : (
                <>
                    {Object.entries(sharedConfig.languages)
                        .filter(([lang]) => displayLanguages.includes(lang))
                        .map(([lang]) => (
                            <li key={lang}>
                                <ExternalOrNavLink
                                    url={"#"}
                                    onClick={() => {
                                        changeLang(pathname, lang as TLang, history, isBlogPage);
                                    }}
                                    className={clsx({
                                        ["fontWeight_bold"]: i18nextInstance.language === lang,
                                    })}
                                >
                                    {lang.toUpperCase()}
                                </ExternalOrNavLink>
                            </li>
                        ))}
                </>
            )}
        </>
    );
}
