import React, { lazy } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import styles from "./_css/unitHeader.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useUnitStore } from "units/UnitContext";
import { PropertyAddress } from "properties/propertyPage/PropertyAddress";
import { Price } from "components/propertyOrUnit/Price";
import { UnitDescription } from "units/UnitDescription";
import Suspenser from "_common/loaders/Suspenser";

const LazyPropertyOrUnitContactForm = lazy(() =>
    import("properties/propertyPage/contact-form/property-or-unit-contact-form"),
);
export const UNIT_CTA_CONTAINER: { current: HTMLDivElement | null } = { current: null };

export function UnitHeader() {
    const unitStore = useUnitStore();
    const { property } = usePropertyStore();
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.container, "flex_row mt_50")}>
            <div className={styles.details}>
                <h1 className={styles.title}>{unitStore.unit.localized.title}</h1>
                <PropertyAddress property={property} />
                <div className={clsx("mt_20")}>
                    {unitStore.unit.priceIsNotDisplayed ? (
                        <div className={styles.price}>{t("propertiesPage.card.priceOnRequest")}</div>
                    ) : (
                        <div className={"mt_20"}>
                            <Price
                                purpose={property.purpose}
                                price={unitStore.unit.price?.min}
                                withTaxes={property.taxIsIncluded}
                            />
                        </div>
                    )}
                </div>
                <UnitDescription />
            </div>
            <div className={clsx(styles.CTA, "flex_column")} ref={(ref) => (UNIT_CTA_CONTAINER.current = ref)}>
                <Suspenser noText noSpinner fallback={<div style={{ width: 400, height: 510 }} />}>
                    <LazyPropertyOrUnitContactForm />
                </Suspenser>
            </div>
        </div>
    );
}
