import React, { lazy, useMemo } from "react";
import { Container, Grid } from "@material-ui/core";
import { PropertyContext } from "properties/propertyPage/PropertyContext";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import i18next from "i18next";
import { LocalLogic } from "properties/localLogic/LocalLogic";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { PageRendererContext } from "pages/PageContext";
import { ComponentRenderer } from "components/ComponentRenderer";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { UnitStore } from "units/_stores/UnitStore";
import { UnitContext } from "units/UnitContext";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitHeader } from "units/unit-header";
import { UnitFloorPlans } from "units/UnitFloorPlans";
import { UnitMeta } from "units/UnitMeta";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { PropertyOrUnitGalleryImageModal } from "gallery/PropertyOrUnitGalleryImageModal";
import { reformatStringForUrls, removeAccentFromString } from "_common/_utils/alphaNumUtils";
import { UnitGallery } from "units/gallery/UnitGallery";
import clsx from "clsx";
import styles from "./_css/unitView.module.css";
import Suspenser from "_common/loaders/Suspenser";
import { PlacementSlider } from "placements/PlacementSlider";

dayjs.locale(i18next.language);

type Props = {
    unit: TUnitListingMdl;
};

const LazyUnitOrPropertyGalleryPage = lazy(() => import("properties/gallery/PropertyOrUnitGalleryPage"));
const LazyNesto = lazy(() => import("properties/nesto/Nesto"));
const LazyPropertySimilarProperties = lazy(() => import("properties/propertyPage/PropertySimilarProperties"));

export function UnitView(props: Props) {
    const { t } = useTranslation();
    const items = [
        {
            itemLabel: t("propertyPage.research"),
            url: props.unit.property.purpose === PROPERTY_PURPOSE.RENT ? URLS.rent() : URLS.buy(),
        },
        {
            itemLabel: props.unit.property.address.city,
            url: URLS[props.unit.property.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                removeAccentFromString(props.unit.property.address.province).replace(" ", "").toLowerCase(),
                reformatStringForUrls(props.unit.property.address.city),
            ),
            forceReload: true,
        },
        {
            itemLabel: props.unit.property.localized.title,
            url: URLS.property(props.unit.property.localized.urlAlias),
        },
    ];
    const propertyStore = useMemo(() => new PropertyStore(props.unit.property), [props.unit.property]);
    const unitStore = useMemo(() => new UnitStore(props.unit), [props.unit]);
    return (
        <PropertyContext propertyStore={propertyStore}>
            <UnitContext unitStore={unitStore}>
                <Container>
                    <UnitMeta property={props.unit.property} unit={props.unit} />
                    <div className={"mt_10"}>
                        <Breadcrumb items={items} currentItem={{ itemLabel: props.unit.localized.title }} />
                    </div>
                    <UnitGallery />
                    <UnitHeader />
                    <div className="mt_50">
                        <LocalLogic property={props.unit.property} />
                    </div>
                </Container>
                {props.unit.property.purpose === PROPERTY_PURPOSE.BUY && (
                    <div className={clsx(styles.background, "mt_50 pt_40 pb_20")}>
                        <Container>
                            <Grid
                                container
                                direction="row"
                                justify="space-around"
                                alignItems="center"
                                alignContent="center"
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Suspenser
                                        noText
                                        noSpinner
                                        fallback={<div style={{ width: "100%", height: 850 }}> </div>}
                                    >
                                        <LazyNesto
                                            price={props.unit.price?.min}
                                            province={props.unit.property.address.province}
                                        />
                                    </Suspenser>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                )}
                {unitStore.unit.floorPlans.length !== 0 && (
                    <Container className={"mb_25 mt_50 mb_40"}>
                        <UnitFloorPlans />
                    </Container>
                )}
                <Suspenser noSpinner noText>
                    <div className={styles.background}>
                        <LazyPropertySimilarProperties />
                    </div>
                </Suspenser>
                <Container>
                    {props.unit?.localized?.component && (
                        <PageRendererContext pageRendererStore={new PageRendererStore()}>
                            <ComponentRenderer component={props.unit.localized.component} isRoot />
                        </PageRendererContext>
                    )}
                </Container>
                <Suspenser noText noSpinner>
                    <LazyUnitOrPropertyGalleryPage
                        resource={MAIN_RESOURCE.UNITS}
                        store={unitStore}
                        propertyGooglePosition={{
                            lat: propertyStore.property.location.coordinates[1],
                            lng: propertyStore.property.location.coordinates[0],
                        }}
                    />
                </Suspenser>
                <PropertyOrUnitGalleryImageModal resource={MAIN_RESOURCE.UNITS} store={unitStore} />
                <PlacementSlider />
            </UnitContext>
        </PropertyContext>
    );
}
