import React from "react";
import { observer } from "mobx-react";
import { Paginator } from "admin/_common/list/Paginator";
import { getUrlWithPagination } from "_common/_utils/searchUtils";
import { useHistory } from "react-router-dom";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { DEFAULT_PAGE_SIZE } from "_common/resources/BaseResourceStore";

type Props = {
    className?: string;
};

export const PropertiesPaginator = observer(({ className }: Props) => {
    const history = useHistory();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);

    const paginationIsDisplay = !!listStore.count && listStore?.count >= DEFAULT_PAGE_SIZE;
    if (!paginationIsDisplay) return null;

    return (
        <Paginator
            onPageClick={(page) => {
                history.push(getUrlWithPagination(location.pathname, page) + location.search);
                listStore.setSelectedPage(page, !listStore.sort);
            }}
            count={listStore.count}
            selectedPage={listStore.selectedPage}
            pageSize={listStore.pageSize}
            className={className}
        />
    );
});
