import React from "react";
import styles from "./_css/popularCities.module.css";
import clsx from "clsx";
import { CityCard } from "home/sections/CityCard";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import "swiper/swiper-bundle.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { POPULAR_CITIES } from "_configs/popularCitiesList";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

export function PopularCities() {
    const { t } = useTranslation();

    return (
        <Container className={styles.container}>
            <h2 className={clsx("flex_column_center", styles.title)}>{t("homePage.popularCities.title")}</h2>
            <Swiper
                loop
                speed={1200}
                autoplay={{ delay: 2000, disableOnInteraction: true }}
                slidesPerView={3}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    750: {
                        slidesPerView: 2,
                    },
                    1100: {
                        slidesPerView: 3,
                    },
                }}
            >
                {POPULAR_CITIES.map((city, idx) => (
                    <SwiperSlide key={idx + city.name}>
                        <CityCard city={city} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container>
    );
}
