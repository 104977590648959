import React, { useRef, useState } from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { observer } from "mobx-react";
import { TabContext } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { VideoComponent } from "components/base/video/VideoComponent";
import { PropertyGalleryTab } from "properties/gallery/PropertyGalleryTab";
import { Map } from "shared/maps/Map";
import clsx from "clsx";
import styles from "shared/properties/gallery/_css/propertyOrUnitGalleryPage.module.css";
import { Gallery } from "gallery/Gallery";
import { Tour3D } from "components/base/tour3D/Tour3D";
import PropertyOrUnitContactForm from "properties/propertyPage/contact-form/property-or-unit-contact-form";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { IconComponent } from "components/base/icon/IconComponent";
import { useFavoriteToggle } from "users/profile/useFavoriteToggle";
import { UnitStore } from "units/_stores/UnitStore";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitPlans } from "units/UnitPlans";
import { PropertyPlans } from "properties/propertyPage/PropertyPlans";
import { GalleryPageTabsButtons } from "properties/gallery/GalleryPageTabsButtons";

export enum GALLERY_TABS {
    GALLERY = "photos",
    TOUR_3D = "visit3D",
    VIDEO = "video",
    STREET_VIEW = "isStreetViewDisabled",
    LEVEL_PLAN = "floorPlans",
}

type Props = {
    store: UnitStore | PropertyStore;
    resource: MAIN_RESOURCE;
    propertyGooglePosition: { lat: number; lng: number };
};

const PropertyOrUnitGalleryPage = observer(({ store, resource, propertyGooglePosition }: Props) => {
    const { t } = useTranslation();
    const [ctaIsOpened, setCtaIsOpened] = useState(false);
    let mapRef: unknown = useRef(null);
    const isUnit = resource === MAIN_RESOURCE.UNITS;
    const item = resource === MAIN_RESOURCE.UNITS ? (store as UnitStore).unit : (store as PropertyStore).property;
    const { isFavorite, toggleFavorite } = useFavoriteToggle(resource, item);
    const title = isUnit
        ? item.bedrooms === 0
            ? t(`unit.type.${item.type}Studio`)
            : t(`unit.type.${item.type}`, {
                  count: item.bedrooms,
              })
        : item.localized.title;

    return (
        <Dialog
            open={store.isGalleryOpened > -1}
            onClose={() => {
                store.closeGallery();
            }}
            maxWidth={"md"}
            fullScreen
            closeCross
            isTransparentBg
            closeSize={"large"}
            withoutPlaceholder
            id={"propertyPageId"}
        >
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    <h2 className={styles.title}>{title}</h2>
                    <div
                        className={clsx("flex_row alignItems_center gap_5", styles.textBtn)}
                        onClick={() => toggleFavorite()}
                    >
                        <IconComponent
                            aria-label="Icon favorite"
                            title="Icon favorite"
                            aria-labelledby="Icon favorite"
                            icon={isFavorite ? "favorite" : "favorite_border"}
                            className={styles.icon}
                        />
                        <span className={styles.favoriteText}>{t("words.addToFavorite")}</span>
                    </div>
                </div>
                <div className={clsx(styles.window, "flex-1")}>
                    <TabContext value={store.openedGalleryTab}>
                        <GalleryPageTabsButtons item={item} store={store} isUnit={isUnit} />
                        <div className={clsx("flex_row", styles.content)}>
                            <div className={clsx("flex-2", styles.galleries)}>
                                <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.GALLERY}>
                                    <Gallery store={store} item={item} />
                                    <div className={styles.contentCtaMobile}>
                                        <PropertyOrUnitContactForm />
                                    </div>
                                </PropertyGalleryTab>
                                {item.visit3D && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.TOUR_3D}>
                                        <Tour3D embedSrc={item.visit3D} />
                                    </PropertyGalleryTab>
                                )}
                                {item.video && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.VIDEO}>
                                        <VideoComponent url={item.video} size={"100%"} />
                                    </PropertyGalleryTab>
                                )}
                                {store.openedGalleryTab === GALLERY_TABS.STREET_VIEW && (
                                    <div>
                                        <Map
                                            id="googleMap"
                                            ref={(ref) => (mapRef = ref)}
                                            mapContainerStyle={{ top: 0, bottom: 0 }}
                                            mapContainerClassName={clsx(styles.map, "flex_row flex-1")}
                                            center={propertyGooglePosition}
                                            options={{
                                                streetView: {
                                                    position: propertyGooglePosition,
                                                    visible: true,
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                                {item.floorPlans.length > 0 && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.LEVEL_PLAN}>
                                        {isUnit ? <UnitPlans /> : <PropertyPlans />}
                                        <div className={styles.contentCtaMobile}>
                                            <PropertyOrUnitContactForm />
                                        </div>
                                    </PropertyGalleryTab>
                                )}
                            </div>
                            <div className={styles.contentCta}>
                                <PropertyOrUnitContactForm />
                            </div>
                        </div>
                    </TabContext>
                </div>
                {/*<div className={clsx(styles.footer, "flex_center_center")}>*/}
                {/*    <UiButton*/}
                {/*        className={clsx(styles.btn, "mb_10")}*/}
                {/*        color={"secondary"}*/}
                {/*        variant={"contained"}*/}
                {/*        onClick={() => setCtaIsOpened(!ctaIsOpened)}*/}
                {/*    >*/}
                {/*        <div>{t("propertyPage.cta.request")}</div>*/}
                {/*    </UiButton>*/}
                {/*</div>*/}
                <Dialog id={"ctaGalleryPropertyPage"} open={ctaIsOpened} onClose={() => setCtaIsOpened(false)}>
                    <PropertyOrUnitContactForm />
                </Dialog>
            </div>
        </Dialog>
    );
});

export default PropertyOrUnitGalleryPage;
