import React, { useState } from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { CheckboxAgreeFooter } from "main/footer/CheckBoxAgreeFooter";
import { ButtonNotification } from "components/misc/notifications/ButtonNotificationProps";
import { notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { useTranslation } from "react-i18next";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { SIGN_UP_TYPE } from "users/_models/UserMdl";
import { observer } from "mobx-react";

type Props = {
    property: TPropertyListingMdl;
};

export const RequestSuccessSubscribe = observer(({ property }: Props) => {
    const form = useForm<{ name: string; email: string }>();
    const { t } = useTranslation();
    const [hasAgreedNotification, setHasAgreedNotification] = useState(false);
    const { loading, setPromise } = useLoadingFromPromise();

    return (
        <FormProvider {...form}>
            <div className={"flex_row gap_50"}>
                <InputBlock
                    className={"mb_15 fontWeight_semi text_xsmall"}
                    label={t("footer.mailLabel")}
                    labelClassName={"ml_5"}
                >
                    <Controller as={Input} name={"email"} control={form.control} aria-label={t("footer.mailLabel")} />
                </InputBlock>
                <InputBlock
                    className={"mb_15 fontWeight_semi text_xsmall"}
                    label={t("footer.nameLabel")}
                    labelClassName={"ml_5"}
                >
                    <Controller as={Input} name={"name"} control={form.control} aria-label={t("footer.nameLabel")} />
                </InputBlock>
            </div>
            <CheckboxAgreeFooter checked={hasAgreedNotification} onChange={setHasAgreedNotification} />
            <ButtonNotification
                variant={"contained"}
                color={"primary"}
                onClick={form.handleSubmit<TContactForm>((data) => {
                    if (loading?.status !== "LOADING") {
                        setPromise(
                            notifierStore.contact({
                                ...data,
                                notification: hasAgreedNotification,
                                propertyId: property._id,
                                signUpType: SIGN_UP_TYPE.FORM_CTA_SUCCESS,
                                signUpFrom: property.purpose,
                            }),
                        );
                    }
                })}
                className={"mt_30"}
            >
                {loading?.status === "LOADING" ? t("loaders.message") : t("footer.subscribe")}
            </ButtonNotification>
        </FormProvider>
    );
});
