import { TUnitDashboardListingMdl, TUnitListingMdl, TUnitLocalized, UNIT_TYPE } from "units/_models/UnitMdl";
import { PROPERTY_STATUS, PROPERTY_TYPE, TPropertyListingMdl, TPropertyMdl } from "properties/_models/PropertyMdl";
import _ from "lodash";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { reformatStringForUrls, removeInlineCssForMceText } from "_common/_utils/alphaNumUtils";
import slugify from "slugify";
import { s4 } from "_common/_utils/coreUtils";

const regExp = new RegExp(/^(Townhouse|Maison de Ville|Adosado)$/);

export function getSortedUnits<T extends (TUnitListingMdl | TUnitDashboardListingMdl)[]>(unit: T): T {
    return unit.sort((a, b) => {
        if (a.position && b.position && a.position !== b.position) {
            return a.position - b.position;
        }
        if (!a || !b) return 1;
        if (regExp.test(a.localized.title) || regExp.test(b.localized.title)) return -1;
        if (a.type === UNIT_TYPE.penthouse || b?.type === UNIT_TYPE.penthouse) {
            return -1;
        }
        return a.bedrooms < b.bedrooms ? -1 : 1;
    });
}
export function getIsLandOrCommercial(property: Pick<TPropertyListingMdl, "type">) {
    return property.type === PROPERTY_TYPE.land || property.type === PROPERTY_TYPE.commercial;
}

export function kindOfUnitBedroomsForProperty(property: TPropertyListingMdl) {
    let kindOfBedrooms: { [key: number]: TUnitLocalized } | undefined = undefined;
    if (!property.units) return undefined;
    for (const bedroom of property.units) {
        if (kindOfBedrooms && bedroom.bedrooms in kindOfBedrooms) continue;
        kindOfBedrooms = { ...(kindOfBedrooms || {}), [bedroom.bedrooms]: bedroom.localized };
    }
    return kindOfBedrooms;
}

export function flattenObject(
    baseObject?: any,
    path = "",
    output: {
        [key: string]: string | number | undefined | null | boolean;
    } = {},
) {
    if (
        baseObject === undefined ||
        baseObject === null ||
        typeof baseObject !== "object" ||
        _.isArray(baseObject) ||
        _.isDate(baseObject)
    ) {
        output[path] = baseObject;
    } else {
        for (const key in baseObject) {
            flattenObject(baseObject[key], path !== "" ? path + "." + key : key, output);
        }
    }
    return output;
}

export function reformatLocalizedPropertyToProperty(
    item: TPropertyMdl,
    fromScraping = false,
    defaultLanguage = "en" as TLang,
) {
    Object.keys(sharedConfig.languages).map((lang) => {
        if (
            item.localized[lang as TLang] &&
            (!item.localized[lang as TLang]?.title || item.localized[lang as TLang]?.title === "")
        ) {
            item.localized[lang as TLang].title = item.localized[defaultLanguage].title;
        }
        if (!item.localized[lang as TLang]?.urlAlias) {
            let urlToSlug = item.localized[lang as TLang]?.title + "-" + s4();
            if (item.address.city) urlToSlug = item.address.city + " " + urlToSlug;
            if (item.address.postalCode) urlToSlug = item.address.postalCode + " " + urlToSlug;
            if (item.address.province) urlToSlug = item.address.province + " " + urlToSlug;
            item.localized[lang as TLang].urlAlias = reformatStringForUrls(
                slugify(urlToSlug, {
                    lower: true,
                }),
            );
        }
        item.localized[lang as TLang].propertyLink = item.localized[defaultLanguage].propertyLink;

        if (fromScraping) {
            if (lang === "fr" || lang === defaultLanguage) {
                item.localized[lang].description =
                    item.localized[lang].description === ""
                        ? removeInlineCssForMceText(item.localized[defaultLanguage].description)
                        : removeInlineCssForMceText(item.localized[lang].description);
            }
        } else {
            item.localized[lang as TLang].description =
                item.localized[lang as TLang].description === ""
                    ? removeInlineCssForMceText(item.localized[defaultLanguage].description)
                    : removeInlineCssForMceText(item.localized[lang as TLang].description);
        }
    });

    if (!item.deliveryDates || item.status === PROPERTY_STATUS.READY_TO_MOVE_IN) {
        item.deliveryDates = [];
    }

    return item;
}

export function getDeveloperName(property: TPropertyListingMdl) {
    return property.propertyBuilderName?.trim() || property.developer?.companyName || "";
}
