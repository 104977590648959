import { i18nextInstance } from "_common/i18n/IntlProvider";

export const URLS = {
    home: (lang?: string) =>
        `/${lang ? (lang === "en" ? "" : lang) : i18nextInstance.language === "en" ? "" : i18nextInstance.language}`,

    auth: {
        base: (lang?: string) => `/${lang || i18nextInstance.language}/auth`,
        signIn: (lang?: string) => `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.signIn")}`,
        signUp: (lang?: string) => `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.signUp")}`,
        askResetPassword: (lang?: string) =>
            `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.askResetPassword")}`,
        resetPassword: (lang?: string) =>
            `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.resetPassword")}`,
        verified: (lang?: string) => `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.verify")}`,
    },

    user: {
        profile: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.profile")}`,
        orders: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.orders")}`,
        savedSearch: (lang?: string) =>
            `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.savedSearch")}`,
        favorites: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.favorites")}`,
        messenger: (lang?: string, property?: string) =>
            `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.messenger")}/${property ? property : ""}`,
    },

    unit: (propertyAlias: string, unitAlias: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t(
            "routes.properties",
        )}/${propertyAlias}/${i18nextInstance.t("routes.units")}/${unitAlias}`,

    property: (propertyAlias: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.properties")}/${propertyAlias}`,

    about: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.about")}`,

    buy: (lang?: string, p1?: string, p2?: string, p3?: string, p4?: string, p5?: string, p6?: string, p7?: string) => {
        let url = `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.buy")}`;
        if (p1) {
            url += `/${p1}`;
            if (p2) {
                url += `/${p2}`;
                if (p3) {
                    url += `/${p3}`;
                    if (p4) {
                        url += `/${p4}`;
                        if (p5) {
                            url += `/${p5}`;
                            if (p6) {
                                url += `/${p6}`;
                                if (p7) {
                                    url += `/${p7}`;
                                }
                            }
                        }
                    }
                }
            }
        }
        return url;
    },

    rent: (
        lang?: string,
        p1?: string,
        p2?: string,
        p3?: string,
        p4?: string,
        p5?: string,
        p6?: string,
        p7?: string,
    ) => {
        let url = `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.rent")}`;
        if (p1) {
            url += `/${p1}`;
            if (p2) {
                url += `/${p2}`;
                if (p3) {
                    url += `/${p3}`;
                    if (p4) {
                        url += `/${p4}`;
                        if (p5) {
                            url += `/${p5}`;
                            if (p6) {
                                url += `/${p6}`;
                                if (p7) {
                                    url += `/${p7}`;
                                }
                            }
                        }
                    }
                }
            }
        }
        return url;
    },

    sell: (lang?: string, region?: string, city?: string, propertyType?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.sell")}/${region}/${city}/${propertyType}`,
    compare: (type: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.compare")}/${type}`,

    advertise: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.advertise")}`,
    pricing: (zone: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.advertise")}/${zone}`,

    resume: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.resume")}`,
    project: (orderId: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.project")}/${orderId}`,

    paymentThanks: (lang?: string, sessionId?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.paymentThanks")}${
            sessionId ? "/" + sessionId : ""
        }`,
    requestSuccess: (propertyAlias: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.requestSuccess")}/${propertyAlias}`,
    blog: (lang?: string, page?: string) => {
        const url = `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.blog")}`;
        if (page) return `${url}/${page}`;
        return url;
    },
    contact: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.contact")}`,

    virtualAssistant: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.virtualAssistant")}`,

    virtualAssistantSummary: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.virtualAssistantSummary")}`,

    policy: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.policy")}`,
    terms: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.terms")}`,
    faq: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.faq")}`,
    cgv: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.cgv")}`,
    sitePlan: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.sitePlan")}`,

    admin: () => "/vistoo-admin",
};
