import React, { lazy } from "react";
import clsx from "clsx";
import { PropertyDetails } from "properties/propertyPage/PropertyDetails";
import { PropertyOptions } from "properties/propertyPage/PropertyOptions";
import styles from "./_css/propertyHeader.module.css";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import { COMPANY_NAME_HIDE_DEMOGRAPHIC } from "properties/propertyPage/PropertyView";
import Suspenser from "_common/loaders/Suspenser";
import { PropertyDescriptionItem } from "properties/propertyPage/PropertyDescriptionItem";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { PropertyDeveloper } from "properties/propertyPage/PropertyDeveloper";
import { PropertyLink } from "properties/propertyPage/PropertyLink";

const LazyPropertyStatistical = lazy(() => import("properties/propertyPage/PropertyStatistical"));

export function PropertyDescription() {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();

    const hasFeaturesOrAmenities = propertyStore.property.features || propertyStore.property.amenities;
    const propertyLink = propertyStore.property.localized.propertyLink;

    return (
        <div className={clsx("mt_50 flex_column", styles.containerDescription)}>
            {propertyStore.property.localized.description && (
                <PropertyDescriptionItem
                    heightOfCollapsable={0}
                    title={t("propertyPage.header.propertyDescription", {
                        projectName: propertyStore.property.localized.title,
                    })}
                >
                    <div className={"space-y-20"}>
                        <div
                            dangerouslySetInnerHTML={{ __html: propertyStore.property.localized.description }}
                            className={clsx("pr_20", styles.text)}
                        />
                        <PropertyDetails />
                        <PropertyDeveloper />
                        {propertyLink && (
                            <div className="mt_10">
                                <PropertyLink propertyLink={propertyStore.property.localized.propertyLink} />
                            </div>
                        )}
                    </div>
                </PropertyDescriptionItem>
            )}
            {hasFeaturesOrAmenities && (
                <PropertyDescriptionItem
                    heightOfCollapsable={0}
                    title={
                        propertyStore.property.type === PROPERTY_TYPE.land
                            ? t("unit.featuresTitleLand")
                            : t("unit.featuresTitle")
                    }
                >
                    <PropertyOptions />
                </PropertyDescriptionItem>
            )}
            {propertyStore.property?.developer?.companyName !== COMPANY_NAME_HIDE_DEMOGRAPHIC && (
                <PropertyDescriptionItem isLastItem heightOfCollapsable={0} title={t("propertyPage.localLogic.title")}>
                    <Suspenser noText noSpinner fallback={<div style={{ width: 400, height: 400 }} />}>
                        <LazyPropertyStatistical />
                    </Suspenser>
                </PropertyDescriptionItem>
            )}
        </div>
    );
}
