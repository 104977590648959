import React, { lazy } from "react";
import { Route, Switch } from "react-router";
import { PageRouter } from "pages/PageRouter";
import { PAGE_REF, PageRegular } from "pages/PageRegular";
import { ScrollTop } from "_common/_utils/ScrollTop";
import { NoSsr } from "@material-ui/core";
import { URLS } from "_configs/URLS";
import { BuyPage } from "properties/BuyPage";
import { RentPage } from "properties/RentPage";
import { PropertyPage } from "properties/PropertyPage";
import { BlogsPage } from "blog/BlogsPage";
import { AuthenticatedRoute } from "_common/_utils/AuthenticatedRoute";
import { UnitPage } from "units/UnitPage";
import { FavoritesPage } from "users/profile/FavoritesPage";
import { SavedSearchesPage } from "users/profile/SavedSearchesPage";
import { AdvertisePage } from "advertise/AdvertisePage";
import { PaymentThanksPage } from "payments/PaymentThanksPage";
import { PricingPage } from "payments/PricingPage";
import { SummaryOrderPage } from "payments/SummaryOrderPage";
import { ProjectPage } from "projects/ProjectPage";
import { OrdersPage } from "users/orders/OrdersPage";
import Suspenser from "_common/loaders/Suspenser";
import { VirtualAssistantPage } from "virtualAssistant/VirtualAssistantPage";
import sharedConfig from "_configs/sharedConfig";
import { VirtualAssistantSummaryPage } from "virtualAssistant/VirtualAssistantSummaryPage";
import { RequestSuccessPage } from "properties/propertyPage/request/RequestSuccessPage";
import ContactPage from "contact/ContactPage";

const LazyComparePageComparePage = lazy(() => import("compare/ComparePage"));
const LazyProfilePage = lazy(() => import("users/profile/ProfilePage"));

export function AppContent() {
    return (
        <PageRegular>
            <Switch>
                <AuthenticatedRoute
                    path={URLS.user.profile(":lang")}
                    component={() => {
                        return (
                            <Suspenser>
                                <LazyProfilePage />
                            </Suspenser>
                        );
                    }}
                />
                <AuthenticatedRoute path={URLS.user.orders(":lang")} component={OrdersPage} />
                <AuthenticatedRoute path={URLS.user.favorites(":lang")} component={FavoritesPage} />
                <AuthenticatedRoute path={URLS.user.savedSearch(":lang")} component={SavedSearchesPage} />
                <Route path={URLS.paymentThanks(":lang", ":sessionId")} component={PaymentThanksPage} />
                <Route path={URLS.blog(":lang", ":page")} component={() => <PageRouter isBlog />} />
                <Route path={URLS.blog(":lang", ":page")} component={BlogsPage} />
                <Route path={URLS.blog(":lang")} component={BlogsPage} />
                <Route path={URLS.blog()} component={BlogsPage} />

                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3", ":p4", ":p5", "p6", "p7")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3", ":p4", ":p5", ":p6")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3", ":p4", ":p5")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3", ":p4")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1")} component={BuyPage} />
                <Route path={URLS.buy(":lang")} component={BuyPage} />

                <Route path={URLS.rent(":lang", ":p1", ":p2", ":p3", ":p4", ":p5", "p6", "p7")} component={RentPage} />
                <Route path={URLS.rent(":lang", ":p1", ":p2", ":p3", ":p4", ":p5", ":p6")} component={RentPage} />
                <Route path={URLS.rent(":lang", ":p1", ":p2", ":p3", ":p4", ":p5")} component={RentPage} />
                <Route path={URLS.rent(":lang", ":p1", ":p2", ":p3", ":p4")} component={RentPage} />
                <Route path={URLS.rent(":lang", ":p1", ":p2", ":p3")} component={RentPage} />
                <Route path={URLS.rent(":lang", ":p1", ":p2")} component={RentPage} />
                <Route path={URLS.rent(":lang", ":p1")} component={RentPage} />
                <Route path={URLS.rent(":lang")} component={RentPage} />
                <Route path={URLS.unit(":propertyAlias", ":unitAlias", ":lang")} component={() => <UnitPage />} />
                <Route path={URLS.property(":propertyAlias", ":lang")} component={PropertyPage} />
                <Route path={URLS.requestSuccess(":propertyAlias", ":lang")} component={RequestSuccessPage} />

                <Route
                    path={URLS.compare(":type", ":lang")}
                    component={() => {
                        return (
                            <Suspenser>
                                <LazyComparePageComparePage />
                            </Suspenser>
                        );
                    }}
                />

                <Route path={URLS.virtualAssistant(":lang")} component={VirtualAssistantPage} />
                <Route path={URLS.virtualAssistantSummary(":lang")} component={VirtualAssistantSummaryPage} />

                <Route path={URLS.pricing(":zone")} component={PricingPage} />
                {!sharedConfig.isProd && <Route path={URLS.pricing(":zone")} component={PricingPage} />}
                <Route path={URLS.resume(":lang")} component={SummaryOrderPage} />
                <Route path={URLS.advertise(":lang")} component={AdvertisePage} />
                <Route path={URLS.project(":lang")} component={ProjectPage} />
                <Route path={URLS.contact(":lang")} component={ContactPage} />

                <Route path="/" component={() => <PageRouter />} />
            </Switch>
            <NoSsr>
                <ScrollTop pageRef={PAGE_REF} />
            </NoSsr>
        </PageRegular>
    );
}
