import React from "react";
import sharedConfig from "_configs/sharedConfig";
import styles from "./_css/propertyPlanPoint.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";

export function PropertyPlanPoint() {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();

    if (!propertyStore.property.planPoint) return null;

    (function () {
        if (__BROWSER__) {
            const hm = document.createElement("script");
            hm.src = "https://live.planpoint.io/static/js/planpoint.js";
            const s = document.getElementsByTagName("script")[0];
            s.parentNode?.insertBefore(hm, s);
        }
    })();

    return (
        <div id="planPoint" className="mt_20">
            <Helmet>
                <link href="https://live.planpoint.io/static/css/main.css" rel="stylesheet" />
            </Helmet>
            <h2>
                {t("propertyPage.planPointTitle")} | {propertyStore.property.localized.title}
            </h2>
            <div
                className={clsx(styles.planPoint, "mv_40")}
                id="planpoint-root"
                data-token={sharedConfig.planPoint.dataToken}
                {...(propertyStore.property.isGroupPlanPoint
                    ? { "data-group": propertyStore.property.planPoint }
                    : { "data-project": propertyStore.property.planPoint })}
                data-lang={i18next.language}
            />
        </div>
    );
}
