import React from "react";
import { AdvancedResearches } from "advancedResearches/AdvancedResearches";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { getSearchResultPageUrlFromGeoZonePurposeAndType } from "_common/_utils/geoZoneUtils";
import { isBuyingPage } from "_common/_utils/pageUtils";
import { useLocation } from "react-router-dom";
import { useSearchPageParams } from "_common/_utils/hookUtils";

export const AdvancedResearchesSRPsGeozonesPropertyTypes = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const { propertyTypeKey: originalPropertyTypeKey } = useSearchPageParams(); // Renommer pour garder la clé originale

    if (!geoZonesStore.geoZone) return null;

    void geoZonesStore.fetchChildrenGeozone(geoZonesStore.geoZone._id);
    const childrenGeoZone = toJS(geoZonesStore.children);
    const purpose = isBuyingPage(location.pathname) ? PROPERTY_PURPOSE.BUY : PROPERTY_PURPOSE.RENT;

    const propertyTypeKey = (() => {
        if (purpose === PROPERTY_PURPOSE.BUY) {
            if (originalPropertyTypeKey === "condo") return "condoWithoutApartment";
            if (originalPropertyTypeKey === "commercial") return "buildingCommercial";
        }
        return originalPropertyTypeKey;
    })();

    const links = [];

    if (childrenGeoZone?.[geoZonesStore.geoZone._id]) {
        const translatedType = t(`property.typesUrl.${originalPropertyTypeKey}`);
        const geoZones = childrenGeoZone[geoZonesStore.geoZone._id];

        links.push(
            ...geoZones.map((geoZone) => {
                const geoZoneName = geoZone.name.split(",")[0];
                let purposeLabel = "";

                if (
                    purpose === PROPERTY_PURPOSE.BUY ||
                    (purpose === PROPERTY_PURPOSE.RENT && propertyTypeKey === PROPERTY_TYPE.house)
                ) {
                    purposeLabel = t("advancedResearches." + purpose.toLowerCase());
                }

                const separator =
                    purpose === PROPERTY_PURPOSE.RENT ? `${t("advancedResearches.at")} ` : t("advancedResearches.at");

                return {
                    label: `${t("advancedResearches." + propertyTypeKey)} ${purposeLabel} ${separator} ${geoZoneName}`,
                    to: getSearchResultPageUrlFromGeoZonePurposeAndType(geoZone, purpose, translatedType),
                };
            }),
        );
    }

    links.sort((a, b) => a.label.localeCompare(b.label));

    return <AdvancedResearches links={links} />;
});
