import React from "react";
import { IFavoritesSearchMdl } from "users/_models/UserMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import clsx from "clsx";
import styles from "./_css/savedSearchesCard.module.css";
import { useTranslation } from "react-i18next";
import i18next, { TFunction } from "i18next";
import { formatPrice } from "_common/_utils/currencyUtils";
import { IconComponent } from "components/base/icon/IconComponent";
import { userStore } from "users/_stores/userStore";
import { IconButton } from "@material-ui/core";
import { FILTER_KEYS, getUrlSearchFromFilters } from "properties/searchBar/filters/_utils/filtersUtils";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { propertiesRentingStore } from "properties/_stores/propertiesRentingStore";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";

type Props = {
    favorite: IFavoritesSearchMdl;
    isNotDeletable?: boolean;
};

const RenderFiltersInData = (filter: TFilter, t: TFunction) => {
    switch (filter.id) {
        case FILTER_KEYS.PURPOSE:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.typeOfPurpose`)}</h4>
                    <div>{t(`propertyPage.${filter.value}`)}</div>
                </div>
            );
        case FILTER_KEYS.TYPE:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.typeOfHouse`)}</h4>
                    {filter.value.length > 0 &&
                        filter.value.map((type: string) => (
                            <div key={type} className={"flex_row mr_10"}>
                                {t(`property.types.${type}`)}
                            </div>
                        ))}
                </div>
            );
        case FILTER_KEYS.BATHROOMS_MAX:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.minNumberOfBathroom`)}</h4>
                    <div>{filter.value}</div>
                </div>
            );
        case FILTER_KEYS.BATHROOMS_MIN:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.maxNumberOfBathroom`)}</h4>
                    <div>{filter.value}</div>
                </div>
            );
        case FILTER_KEYS.BEDROOMS_MAX:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.minNumberOfBedroom`)}</h4>
                    <div>{filter.value}</div>
                </div>
            );

        case FILTER_KEYS.BEDROOMS_MIN:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.maxNumberOfBedroom`)}</h4>
                    <div>{filter.value}</div>
                </div>
            );
        case FILTER_KEYS.PRICE_MIN:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.maxPrice`)}</h4>
                    <div>{formatPrice(filter.value)}</div>
                </div>
            );
        case FILTER_KEYS.PRICE_MAX:
            return (
                <div className={"flex_row"}>
                    <h4 className={"mr_10"}>{t(`favoritesPage.minPrice`)}</h4>
                    <div>{formatPrice(filter.value)}</div>
                </div>
            );
        default:
    }
};

function getSearchUrlAndSetListStoreFilters(favorite: IFavoritesSearchMdl, filters: TFilter[]) {
    const purposeFilter = filters.find((filter) => filter.id === FILTER_KEYS.PURPOSE);
    const locationFilter = filters.find((filter) => filter.id === FILTER_KEYS.LOCATION);
    const search = getUrlSearchFromFilters(filters);
    if (purposeFilter && locationFilter) {
        switch (purposeFilter.value) {
            case PROPERTY_PURPOSE.BUY:
                propertiesBuyingStore.getListStore().removeFilterByFilterId(FILTER_KEYS.LOCATION);
                propertiesBuyingStore.getListStore().updateFilterAndReload(locationFilter);
                propertiesBuyingStore.setLocation(locationFilter.value);
                propertiesBuyingStore.addressParams.city = favorite.city;
                propertiesBuyingStore.addressParams.region = favorite.region;
                return `${URLS.buy(
                    i18next.language,
                    reformatStringForUrls(favorite.region),
                    reformatStringForUrls(favorite.city),
                )}?${search}`;
            case PROPERTY_PURPOSE.RENT:
                propertiesRentingStore.getListStore().removeFilterByFilterId(FILTER_KEYS.LOCATION);
                propertiesRentingStore.getListStore().updateFilterAndReload(locationFilter);
                propertiesRentingStore.setLocation(locationFilter.value);
                propertiesRentingStore.addressParams.city = favorite.city;
                propertiesRentingStore.addressParams.region = favorite.region;
                return `${URLS.rent(
                    i18next.language,
                    reformatStringForUrls(favorite.region),
                    reformatStringForUrls(favorite.city),
                )}?${search}`;
        }
    }
}

export function SavedSearchesCard(props: Props) {
    const filters: TFilter[] = JSON.parse(props.favorite.filters);
    const { t } = useTranslation();
    return (
        <div className={clsx("mb_20 p_20", styles.container)}>
            <a href={getSearchUrlAndSetListStoreFilters(props.favorite, filters)}>
                {props.favorite.search && <h3>&quot;{props.favorite.search}&quot;</h3>}
                {filters.map((filter, key) => (
                    <div key={key}>{RenderFiltersInData(filter, t)}</div>
                ))}
                {props.favorite.city && (
                    <div className={"flex_row"}>
                        <h4>{t("address.address")}:</h4>
                        {props.favorite.neighborhood && <div>&nbsp;{props.favorite.neighborhood},</div>}
                        <div>&nbsp;{props.favorite.city}</div>
                        <div>{props.favorite.region ? ", " + props.favorite.region : ""}</div>
                    </div>
                )}
                {!props.isNotDeletable && (
                    <IconButton
                        aria-label="close"
                        className={styles.icon}
                        onClick={(e) => {
                            e.preventDefault();
                            userStore.deleteSearchToFavorite(props.favorite);
                        }}
                    >
                        <IconComponent icon={"close"} />
                    </IconButton>
                )}
            </a>
        </div>
    );
}
